<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Accordion</h1>

            <a href="" class="pl-10">
              <div class="pagehead-link circle red-100">
                <i class="icon font-red fa-solid fa-link"></i>
              </div>
            </a>
            
            </div>
            <p>
              Accordions allow users to show and hide sections of related
              content.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <div class="pt-20">
                  <h5 class="semibold">When to Consider Accordions</h5>
                  <ul class="bullet-red">
                    <li>
                      If it benefits users to see an overview of related
                      sections of content that might otherwise be on different
                      pages.
                    </li>
                    <li>
                      If content can be usefully and logically separated into
                      clearly labeled sections.
                    </li>
                    <li>
                      If users need to be able to view more than one section at
                      a time.
                    </li>
                    <li>If there are many sections of content.</li>
                  </ul>
                </div>
                <div class="pt-20">
                  <h5 class="semibold">When to Consider Accordions</h5>
                  <ul class="bullet-red">
                    <li>
                      If content sections are equally important and need to be
                      seen by all users.
                    </li>
                    <li>
                      If there is not enough content to warrant condensing.
                    </li>
                  </ul>
                </div>
                <div class="pt-20">
                  <h5 class="semibold">Caution!</h5>
                  <ul class="bullet-red">
                    <li>
                      Careful consideration is required before using this
                      component, since Accordions hide content.
                    </li>
                    <ul class="bullet-red">
                      <li>
                        Not all users will notice Accordions or know how to use
                        Accordions. Some users may not see all content.
                      </li>
                      <li>
                        Accordions increase cognitive load and interaction cost
                        because users must make decisions as to what to click
                        on. .
                      </li>
                    </ul>
                    <li>
                      It is often easier for the user to scroll down a page then
                      to decide what topics to click. Users will scroll when
                      information is valuable and properly formatted.
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <div class="divider"></div>
            <section id="accessibility">
              <div class="content-band">
                <h2>Accessibility</h2>
                <ul class="bullet-red">
                  <li>
                    Accordion headers are coded as buttons to be usable by both
                    keyboard-only and screen reader users.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="accordion-css" />
              </div>
            </section>
            <div class="divider"></div>

            <section id="accordion-with-background">
              <div class="content-band">
                <h3>Accordion</h3>
                <CodeView
                  component="accordion"
                  type="accordion-with-background"
                >
                  <AccordionComponent type="primary" />
                </CodeView>
              </div>
            </section>
            <section id="accordion-with-border">
              <div class="content-band">
                <h3>Accordion</h3>
                <CodeView component="accordion" type="accordion-with-border">
                  <AccordionComponent type="minimal" />
                </CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import AccordionComponent from "../Accordions/AccordionComponent";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
import StickyTOC from "../../StickyTOC";
export default {
  name: "Accordion",
  data: function() {
    return {
      primary: {
        main: {
          codeHTML: `<main class="">
  <details open="open">
    <summary>Why do robots smile?</summary>
    <div class="faq__content">
      <p>They are machines pretending to like you.</p>
      <ul>
        <li>
          Ut enim ad minim veniam, quis nostrud exercitation
        </li>
        <li data-v-0ba99845="">Velit esse cillum dolore eu fugiat nulla</li>
        <li data-v-0ba99845="">
          Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore
        </li>
      </ul>
    </div>
  </details>
  <details data-v-0ba99845="">
    <summary data-v-0ba99845="">
      Lorem ipsum dolor sit amet, consectetur adipiscing.
    </summary>
    <div class="faq__content">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur.
      </p>
    </div>
  </details>
  <details>
    <summary>
      Quis nostrud exercitation ullamco laboris
    </summary>
    <div class="faq__content">
      <p>
        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </p>
    </div>
  </details>
</main>
`,
          codeCSS: `main {
  width: 100%;
  margin: 0 auto;
}
details {
  max-width: 720px;
}
summary {
  font-size: 17px;
  font-weight: 600;
  background-color: #fafafa;
  color: rgb(26, 26, 26);
  padding: 20px;
  margin-bottom: 10px;
  outline: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.faq__content {
  padding: 5px 20px;
}
.faq__content > ul li {
  line-height: 22px;
}
p {
  text-align: left;
}
details[open] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}
details > summary::after {
  position: absolute;
  content: "+";
  top: 20px;
  right: 20px;
}
summary {
  transition: all 0.3s ease-in-out;
}
summary:hover {
  background: var(--prime-blue-5);
}
details[open] summary {
  background: var(--prime-blue-5);
}
details[open] > summary::after {
  position: absolute;
  content: "-";
  top: 19px;
  right: 22px;
}
details > summary::-webkit-details-marker {
  display: none;
}
@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}`,
          codeJS: "// No JS needed",
          preview: "This is the preview",
          name: "Accordion | Standard",
        },
      },
      minimal: {
        main: {
          name: "Accordion Minimal",
          codeHTML: `<main class="accordion_minimal">
  <details open="open">
    <summary>Why do robots smile?</summary>
    <div class="faq__content">
      <p>They are machines pretending to like you.</p>
      <ul>
        <li>
          Ut enim ad minim veniam, quis nostrud exercitation
        </li>
        <li>Velit esse cillum dolore eu fugiat nulla</li>
        <li>
          Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore
        </li>
      </ul>
    </div>
  </details>
  <details>
    <summary>
      Lorem ipsum dolor sit amet, consectetur adipiscing.
    </summary>
    <div class="faq__content">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur.
      </p>
    </div>
  </details>
  <details>
    <summary>
      Quis nostrud exercitation ullamco laboris
    </summary>
    <div class="faq__content">
      <p>
        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </p>
    </div>
  </details>
</main>
`,
          codeCSS: `main {
  width: 100%;
  margin: 0 auto;
}
details {
  max-width: 720px;
}
summary {
  font-size: 17px;
  font-weight: 600;
  background-color: #fafafa;
  color: rgb(26, 26, 26);
  padding: 20px;
  margin-bottom: 10px;
  outline: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.faq__content {
  padding: 5px 20px;
}
.faq__content > ul li {
  line-height: 22px;
}
p {
  text-align: left;
}
details[open] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}
details > summary::after {
  position: absolute;
  content: "+";
  top: 20px;
  right: 20px;
}
summary {
  transition: all 0.3s ease-in-out;
}
summary:hover {
  background: var(--prime-blue-5);
}
details[open] summary {
  background: var(--prime-blue-5);
}
details[open] > summary::after {
  position: absolute;
  content: "-";
  top: 19px;
  right: 22px;
}
details > summary::-webkit-details-marker {
  display: none;
}
@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
main.accordion_minimal {
  width: 100%;
}
main.accordion_minimal details:nth-child(1) {
  border-top: 1px solid #dadada;
}
main.accordion_minimal details {
  width: 100%;
  border-bottom: 1px solid #dadada;
}
main.accordion_minimal details > summary {
  position: relative;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  color: rgb(26, 26, 26);
  text-align: left;
  padding: 20px;
  margin-bottom: 0;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
main.accordion_minimal details > summary:hover {
  background: none;
}
main.accordion_minimal details[open] summary {
  background: none;
}
main.accordion_minimal details > .faq__content {
  padding-bottom: 20px;
}`,
          codeJS: `// No JS needed`,
        },
      },
    };
  },
  components: {
    CodeView,
    ImportLinkComponent,
    AccordionComponent,
    StickyTOC,
    TempLeftNav,
    SubFooter,
  },
};
</script>
