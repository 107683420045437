<template>
  <div class="page-container">
    <div class="content-band max-w1000">
      <main :class="{ accordion_minimal: type === 'minimal' }">
        <details open>
          <summary>Why do robots smile?</summary>
          <div class="faq__content">
            <p>They are machines pretending to like you.</p>
            <ul>
              <li>Ut enim ad minim veniam, quis nostrud exercitation</li>
              <li>Velit esse cillum dolore eu fugiat nulla</li>
              <li>
                Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore
              </li>
            </ul>
          </div>
        </details>

        <details>
          <summary>Lorem ipsum dolor sit amet, consectetur adipiscing.</summary>
          <div class="faq__content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </div>
        </details>

        <details>
          <summary>Quis nostrud exercitation ullamco laboris</summary>
          <div class="faq__content">
            <p>
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat.
            </p>
          </div>
        </details>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionComponent",
  props: ["type"],
};
</script>

<style scoped>
main {
  width: 100%;
  margin: 0 auto;
}
details {
  max-width: 720px;
}
summary {
  font-size: 17px;
  font-weight: 600;
  background-color: #fafafa;
  color: rgb(26, 26, 26);
  padding: 20px;
  margin-bottom: 10px;
  outline: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.faq__content {
  padding: 5px 20px;
}
.faq__content > ul li {
  line-height: 22px;
}
p {
  text-align: left;
}
details[open] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}
details > summary::after {
  position: absolute;
  content: "+";
  top: 20px;
  right: 20px;
}
summary {
  transition: all 0.3s ease-in-out;
}
summary:hover {
  background: var(--prime-blue-5);
}
details[open] summary {
  background: var(--prime-blue-5);
}
details[open] > summary::after {
  position: absolute;
  content: "-";
  top: 19px;
  right: 22px;
}
details > summary::-webkit-details-marker {
  display: none;
}
@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

/* 
    Accordion modifiers - Minimal, outline
*/
main.accordion_minimal {
  width: 100%;
}
main.accordion_minimal details:nth-child(1) {
  border-top: 1px solid #dadada;
}
main.accordion_minimal details {
  width: 100%;
  border-bottom: 1px solid #dadada;
}
main.accordion_minimal details > summary {
  position: relative;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  color: rgb(26, 26, 26);
  text-align: left;
  padding: 20px;
  margin-bottom: 0;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
main.accordion_minimal details > summary:hover {
  background: none;
}
main.accordion_minimal details[open] summary {
  background: none;
}
main.accordion_minimal details > .faq__content {
  padding-bottom: 20px;
}
</style>
